.password_messages_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

#message {
  display: none;
  background: #fcfcfc;
  color: #000;
  position: relative;
  padding: 20px;
  margin-top: 10px;
  width: 30%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 10px;
  /* text-align:center; */
}

#message p {
  padding: 5px 35px;
  font-size: 18px;
  /* text-align:center; */
}

.react-tel-input .form-control {
  height: 45px !important;
  width: 100% !important;
}

#details_message {
  background: #fcfcfc;
  color: #000;
  position: relative;
  padding: 20px;
  margin-top: 10px;
  width: 30%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 10px;
  /* text-align:center; */
}

#details_message p {
  padding: 5px 35px;
  font-size: 18px;
  /* text-align:center; */
}

/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: green;
}

.valid:before {
  position: relative;
  left: -35px;
  content: "✔";
}

/* Add a red text color and an "x" when the requirements are wrong */
.invalid {
  color: red;
}

.invalid:before {
  position: relative;
  left: -35px;
  content: "✖";
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}