.kep-login-facebook
{
    width: 100%!important;
    padding: 11px!important;
    border: none!important;
    border-radius: 4px!important;
    margin: 30px 0 0 0!important;
    opacity: 0.85!important;
    display: inline-block!important;
    font-size: 17px!important;
    line-height: 20px!important;
    text-decoration: none!important; /* remove underline from anchors */
    background-color: #3B5998!important;
    color: white!important;
    text-transform:none!important;
    text-align: left!important;
}

.kep-login-facebook:hover
{
    opacity:1!important;
}

.google-container button
{
    width:125%!important;
    border-radius:3px!important;
}