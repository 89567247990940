.new-instance-enomos .main-content {
    background: linear-gradient(to right, white, #26575F);
}

.new-instance-enomos .header.pb-4.pt-5 {
    background-image: none !important;
}

.new-instance-enomos .header.pb-4 {
    background: none !important;
}

.my-specific-page-footer {
    background: none !important;
  }